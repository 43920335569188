<template>
  <div class="global">
    <div class="title text-t1 text-bold mb-60">
      {{ $t("newTheme.safeTrans") }}
    </div>
    <!-- <div class="info text-t2"></div> -->
    <div class="img-box flex js-ct">
      <div class="small-box">
        <img src="@/assets/theme1/imte1.png" alt />
        <div class="small-title text-16 text-t1">
          {{ $t("newTheme.clodWallet") }}
        </div>
        <div class="small-info color-42 text-14">
          {{ $t("newTheme.tip1") }}
        </div>
      </div>
      <div class="small-box ml-110">
        <img src="@/assets/theme1/imte2.png" alt />
        <div class="small-title text-16 text-t1">
          {{ $t("newTheme.systemSecurity") }}
        </div>
        <div class="small-info color-42 text-14">
          {{ $t("newTheme.tip2") }}
        </div>
      </div>
      <div class="small-box ml-110">
        <img src="@/assets/theme1/imte3.png" alt />
        <div class="small-title text-16 text-t1">
          {{ $t("newTheme.TwoFactor") }}
        </div>
        <div class="small-info color-42 text-14">
          {{ $t("newTheme.tip3", { user: state.user }) }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { reactive, onMounted } from "vue";
export default {
  setup() {
    const state = reactive({
      user: process.env.VUE_APP_USER,
    });
    onMounted(() => {});
    return {
      state,
    };
  },
};
</script>

<style lang="less" scoped>
.global {
  padding: 80px 180px;
  background: #f8f8f8;
}
.title {
  width: 100%;
  font-size: 30px;
  margin-bottom: 10px;
}
.info {
  width: 100%;
  font-size: 14px;
  margin-bottom: 60px;
}
.img-box {
  width: 100%;
  margin: 0 auto;
}
.small-box {
  width: 300px;
  img {
    width: 300px;
    height: 200px;
    margin-bottom: 30px;
  }
}
.small-title {
  font-family: PingFang SC;
  font-style: normal;
  font-weight: 600;
  margin-bottom: 10px;
}
.small-info {
  font-family: PingFang SC;
  line-height: 20px;
}
.mb-60 {
  margin-bottom: 60px;
}
.color-42 {
  color: #424344;
}
.ml-110 {
  margin-inline-start: 110px;
}
</style>
