<template>
  <div class="container">
    <div>
      <div class="banner">
        <div class="detail flex align-center js-ct">
          <div class="text-left">
            <div class="tit main-color">
              {{ $t("newTheme.getFirstCoin", { appName: state.appName }) }}
            </div>
            <div class="font-24 mb-102 main-color">
              {{ $t("newTheme.mobileDownload") }}
            </div>
            <div class="flex align-center" v-if="!state.islogin">
              <div
                class="creadt-btn login-btn text-center point"
                @click="toPath('/register')"
              >
                {{ $t("newTheme.createAccount") }}
              </div>
              <div
                class="font-14 text-t1 ml-22 point"
                @click="toPath('/login')"
              >
                {{ $t("newTheme.haveAccount") }}
              </div>
            </div>
          </div>
          <img src="@/assets/theme1/banner1.png" alt="" class="phone-img" />
        </div>
      </div>
      <div class="item bg-ff flex align-center js-ct">
        <img src="@/assets/theme1/trans.png" alt="" class="imgs " />
        <div class="ml-112  text-left">
          <div class="title">
            <div>{{ $t("newTheme.buyAndSell") }}</div>
            <div>{{ $t("newTheme.Cryptocurrency") }}</div>
          </div>
          <div class="desc w-400">{{ $t("newTheme.lowerPrices") }}</div>
        </div>
      </div>
      <div class="item flex align-center js-ct">
        <div class=" text-left">
          <div class="title w-400">
            <div>{{ $t("newTheme.WithdraworRecharge") }}</div>
          </div>
          <div class="desc w-400">
            {{ $t("newTheme.WithdrawTip") }}
          </div>
        </div>
        <img src="@/assets/theme1/cash.png" alt="" class="imgs ml-120" />
      </div>
      <div
        class="item bg-ff flex align-center js-ct"
        v-if="state.currency_transaction_status"
      >
        <img src="@/assets/theme1/deal.png" alt="" class="imgs " />
        <div class="ml-112  text-left">
          <div class="title">
            <div>{{ $t("newTheme.transactionView") }}</div>
            <div>{{ $t("newTheme.professionalQuality") }}</div>
          </div>
          <div class="desc w-400">
            {{ $t("newTheme.browser", { plug: state.plug }) }}
          </div>
          <div class="font-14 text-t1 mt-12 point" @click="toPath('/deal')">
            <span>{{ $t("newTheme.LearnMore") }}</span
            ><i class="el-icon-arrow-right text-t1"></i>
          </div>
        </div>
      </div>
    </div>
    <div class="item flex align-center js-ct" v-if="state.lever">
      <div class=" text-left">
        <div class="title">
          <div>{{ $t("newTheme.lowerMargin") }}</div>
          <div>{{ $t("newTheme.higherLeverage") }}</div>
        </div>
        <div class="desc w-400">
          {{ $t("newTheme.higherReturns") }}
        </div>
        <div class="font-14 text-t1 mt-12 point" @click="toPath('/lever')">
          <span>{{ $t("newTheme.LearnMore") }}</span
          ><i class="el-icon-arrow-right text-t1"></i>
        </div>
      </div>
      <img src="@/assets/theme1/level.png" alt="" class="imgs ml-120" />
    </div>
    <!-- </div> -->
    <!-- 安全可靠交易 -->
    <Globalimage />
    <div
      class="item bg-ff "
      v-if="state.defi || state.wealth || state.secondLever"
    >
      <div class="another text-t1 text-bold mb-60">
        {{ $t("newTheme.OtherProducts") }}
      </div>
      <div class="flex align-center js-ct">
        <div
          class="itembox flex align-center text-left"
          v-if="state.secondLever"
        >
          <div class="w-300">
            <div class="font-24 weight-900">{{ $t("newTheme.options") }}</div>
            <div class="font-14 opacity mb-60 mt-12">
              {{ $t("newTheme.quickReturn") }}
            </div>
            <div class="font-14  point" @click="toPath('/secondlever')">
              <span>{{ $t("newTheme.LearnMore") }}</span
              ><i class="el-icon-arrow-right "></i>
            </div>
          </div>
          <img src="@/assets/theme1/second.png" alt="" srcset="" class="img1" />
        </div>
        <div
          class="itembox flex align-center text-left ml-40"
          v-if="state.defi || state.wealth"
        >
          <div class="w-300">
            <div class="font-24  weight-900">
              {{ $t("newTheme.MoneyManagementMining") }}
            </div>
            <div class="font-14 opacity mb-60 mt-12">
              {{ $t("newTheme.LockCryptocurrency") }}
            </div>
            <div
              class="font-14  point"
              @click="
                toPath(state.defi ? '/defiFinancial' : '/wealthFinancial')
              "
            >
              <span>{{ $t("newTheme.LearnMore") }}</span
              ><i class="el-icon-arrow-right "></i>
            </div>
          </div>
          <img src="@/assets/theme1/defi.png" alt="" srcset="" class="img2" />
        </div>
      </div>
    </div>
    <div
      class="server-box flex align-center js-ct"
      @click="toServePath"
      v-if="state.service_url === 'contact'"
    >
      <img
        src="@/assets/images/serverbox.png"
        alt=""
        srcset=""
        class="server-icon"
      />
    </div>
    <!-- 新增的第三方客服 -->
    <!-- <div
      class="server-box flex align-center js-ct"
      v-if="state.service_url === 'online'"
    >
      <a href="#" onclick="psOpenWindow(); return false;"
        ><img
          src="@/assets/images/serverbox.png"
          alt=""
          srcset=""
          class="server-icon"
      /></a>
    </div> -->
    <!-- 客服弹窗 -->
    <ServerBox
      @oncancer="state.dialog = false"
      @onconfirm="state.dialog = false"
      :dialog="state.dialog"
    />
    <div class="box">
      <el-dialog
        v-model="state.dialogVisible"
        v-if="state.dialogParam.content_type == 1"
        :close-on-click-modal="false"
        center
        top="210px"
        width="400px"
      >
        <div class="dialog-box">
          <img src="@/assets/dialog.png" alt="" srcset="" class="dialog-img" />
          <div
            v-html="state.dialogParam.content_text"
            class="dialog-desc"
          ></div>
          <div
            class="letter-btn share-color text-16 text-bold login-btn text-center point"
            @click="toDialogPath"
          >
            {{ $t("global.tryNow") }}
          </div>
        </div>
      </el-dialog>
      <div class="img-box">
        <el-dialog
          v-model="state.dialogVisible"
          center
          :show-close="false"
          top="210px"
          width="400px"
          v-if="state.dialogParam.content_type == 2"
        >
          <img
            :src="state.dialogParam.source_url"
            alt=""
            srcset=""
            class="dialog-img"
            @click="toDialogPath"
          />
        </el-dialog>
      </div>
    </div>
  </div>
</template>

<script>
import { reactive, onMounted, computed, watch } from "vue";
import Globalimage from "@/components/homeModel/safety-trans";
import { getHomeWindowsStatus } from "@/api/system";
import { useStore } from "vuex";
import { useRouter } from "vue-router";
import { ElMessage } from "element-plus";
import { useI18n } from "vue-i18n";
import ServerBox from "@/components/homeModel/serverBox";
import jumpChat from "@/commonapi/chat";
export default {
  components: { ServerBox, Globalimage },
  setup() {
    const $store = useStore();
    const { t } = useI18n();
    const state = reactive({
      appName: window.VUE_APP_NAME,
      islogin: $store.state.user.token ? true : false,
      plug: process.env.VUE_APP_PLUG,
      wealth_management_status: false,
      defi_ore_status: false,
      dialog: false,
      service_url: "", //判断客服跳转类型
      currency_transaction_status: true, //币币交易
      dialogVisible: false,
      dialogParam: {},
      defi: false,
      wealth: false,
      lever: false,
      secondLever: false,
    });
    // methods
    // const $store = useStore();
    const $router = useRouter();
    const systemInfo = computed(() => {
      return $store.state.user.systemInfo;
    });
    watch(systemInfo, () => {
      getConfig();
      getHomeDialogStatus();
    });
    // const $route = useRoute();
    const toPath = (url) => {
      if (url === "/defiFinancial") {
        // let wealth_management_status = false;
        systemInfo.value?.forEach((ele) => {
          if (ele.key === "wealth_management_status") {
            state.wealth_management_status = ele.value;
          }
          if (ele.key === "defi_ore_status") {
            state.defi_ore_status = ele.value;
          }
        });
        if (state.defi_ore_status) {
          $router.push(url);
        } else {
          if (state.wealth_management_status) {
            $router.push("/wealthFinancial");
          } else {
            ElMessage.error(t("newTheme.noOpenTip"));
          }
        }
      } else {
        $router.push(url);
      }
    };
    //判断客服跳转
    const toServePath = () => {
      if (state.service_url === "contact") {
        state.dialog = true;
      }
    };
    // 获取配置
    const getConfig = () => {
      systemInfo.value?.forEach((ele) => {
        if (ele.key === "service_url") {
          const show = ele.value; //online-在线客服页；contact-社交客服页
          state.service_url = show;
          state.dialog = false;
          if (state.service_url === "contact") {
            // zE(function() {
            //   zE.hide();
            // });
            //更新的接口
            // window.zE("messenger", "hide");
          } else {
            const { chat } = jumpChat();
            chat();
            // 在线客服
          }
        }
        if (ele.key === "defi_ore_status") {
          state.defi = ele.value;
        }
        if (ele.key === "wealth_management_status") {
          state.wealth = ele.value;
        }
        if (ele.key === "lever_status") {
          state.lever = ele.value;
          console.log(state.lever);
        }
        if (ele.key === "second_lever_status") {
          state.secondLever = ele.value;
        }
        if (ele.key === "currency_transaction_status") {
          state.currency_transaction_status = ele.value;
        }
      });
    };
    //首页弹窗点击跳转
    const toDialogPath = () => {
      if (state.dialogParam.skip_type === 1) {
        if (state.dialogParam.skip_url) {
          window.open(state.dialogParam.skip_url);
        }
      } else {
        if (state.dialogParam.skip_url) {
          if (state.dialogParam.skip_url === "defi" && state.defi) {
            $router.push("/defiFinancial");
          }
          if (state.dialogParam.skip_url === "wealth" && state.wealth) {
            $router.push("/wealthFinancial");
          }
          if (state.dialogParam.skip_url === "lever" && state.lever) {
            $router.push("/lever");
          }
          if (
            state.dialogParam.skip_url === "secondLever" &&
            state.secondLever
          ) {
            $router.push("/secondlever");
          }
          if (
            state.dialogParam.skip_url === "currency_transaction_status" &&
            state.currency_transaction_status
          ) {
            $router.push("/deal");
          }
          if (
            state.dialogParam.skip_url === "fastExchange" &&
            state.fastExchange
          ) {
            $router.push("/flashAgainst");
          }
        }
      }
    };
    const getHomeDialogStatus = () => {
      getHomeWindowsStatus().then((res) => {
        if (!res.data) {
          return false;
        }
        state.dialogParam = res.data;
        //没弹过弹窗或者弹窗内容id变过或者弹窗是每次都要弹的或者激活的时间不一致
        state.dialogVisible =
          !$store.state.user.activeParam.showStatus ||
          res.data.id != $store.state.user.activeParam.id ||
          res.data.windows_times === 2 ||
          res.data.activation_time !=
            $store.state.user.activeParam.activation_time;
        let obj = {
          activation_time: res.data.activation_time, //激活时间
          id: res.data.id, //内容id
          showStatus: true, //是否弹出过
        };
        $store.commit("user/SET_ACTIVEPARAM", obj);
      });
    };
    onMounted(() => {
      getConfig();
      getHomeDialogStatus();
    });
    return {
      state,
      toPath,
      systemInfo,
      toServePath,
      getConfig,
      toDialogPath,
      getHomeDialogStatus,
    };
  },
};
</script>

<style lang="less" scoped>
.container {
  width: 100%;
  height: auto;
  background: #eef1f6;
  .banner {
    width: 100%;
    position: relative;
    background-color: #f4f7f9;
    // background-image: url("../assets/theme1/banner.png");
    // background-repeat: no-repeat;
    // background-size: cover;
    // background-position: center;
    .detail {
      min-height: 763px;
      // position: absolute;
      padding: 78px 160px 6px 160px;
      height: 100%;
      width: 100%;
      // top: 0;
      // left: 0;
      box-sizing: border-box;
    }
    .phone-img {
      width: 545px;
      height: 100%;
      display: block;
      margin-inline-start: 108px;
    }
    .tit {
      font-family: DINPro;
      font-style: normal;
      font-weight: 900;
      font-size: 48px;
      line-height: 62px;
      width: 467px;
      margin-bottom: 26px;
    }
    img {
      width: 100%;
      height: auto;
      display: block;
    }
  }
  .title {
    font-family: PingFang SC;
    font-weight: 700;
    font-size: 42px;
    line-height: 59px;
    color: #424344;
    margin-bottom: 32px;
  }
  .desc {
    font-size: 16px;
    line-height: 22px;
    color: #989898;
  }
  .creadt-btn {
    // background: #11c1a4;
    border-radius: 6px;
    width: 216px;
    height: 60px;
    line-height: 60px;
    color: #fff;
    font-size: 20px;
    font-weight: bold;
  }
  .item {
    padding: 80px 0px;
    .imgs {
      display: block;
      height: 400px;
      width: 400px;
    }
  }
}
.itembox {
  padding-inline-start: 60px;
  box-sizing: border-box;
  background: @another;
  width: 540px;
  height: 300px;
  position: relative;
  color: #fff;
  .img1 {
    position: absolute;
    right: 37px;
    bottom: 21px;
    opacity: 0.8;
    display: block;
    width: 157px;
    height: auto;
  }
  .img2 {
    width: 173px;
    height: auto;
    position: absolute;
    right: 29px;
    bottom: 36px;
    display: block;
  }
}
.is-rtl {
  .itembox {
    .img1 {
      left: 37px;
      right: auto;
    }
    .img2 {
      left: 29px;
      right: auto;
    }
  }
}
.bg-ff {
  background: #fff;
}
.main-color {
  color: #12575f;
}
.mb-102 {
  margin-bottom: 102px;
}
.ml-112 {
  margin-inline-start: 112px;
}
.ml-120 {
  margin-inline-start: 120px;
}
.mt-12 {
  margin-top: 12px;
}
.ml-40 {
  margin-inline-start: 40px;
}
.ml-22 {
  margin-inline-start: 22px;
}
.w-400 {
  width: 400px;
}
.opacity {
  opacity: 0.6;
}
.mb-60 {
  margin-bottom: 60px;
}
.weight-900 {
  font-weight: 900;
}
.font-24 {
  font-size: 24px;
  line-height: 34px;
}
.font-14 {
  font-size: 14px;
  line-height: 20px;
}
.another {
  width: 100%;
  font-size: 30px;
  margin-bottom: 10px;
}
.mb-60 {
  margin-bottom: 60px;
}
.server-box {
  position: fixed;
  right: 60px;
  bottom: 60px;
  background: rgba(17, 193, 164, 0.5);
  width: 100px;
  height: 100px;
  border-radius: 50%;
  filter: drop-shadow(0px 8px 14px rgba(17, 193, 164, 0.3));
  z-index: 1999;
}
.server-icon {
  width: 67.5px;
  height: 67.5px;
}
.box /deep/.el-dialog__header {
  border-bottom: none;
  padding: 0;
}
.mb-10 {
  margin-bottom: 10px;
}
.text-semibold {
  font-weight: 600;
}
.dialog-box {
  padding: 15px 15px 0px;
  .dialog-img {
    width: 100%;
    height: 154px;
    display: block;
    margin-bottom: 24px;
  }
  .dialog-desc {
    color: #888;
    margin-bottom: 24px;
  }
  .letter-btn {
    width: 100%;
    height: 46px;
    line-height: 46px;
    border-radius: 4px;
  }
}
.box /deep/.el-dialog__header {
  border-bottom: none;
  padding: 0;
}
.text-semibold {
  font-weight: 600;
}
.dialog-box {
  padding: 15px 15px 0px;
  .dialog-img {
    width: 100%;
    height: 154px;
    display: block;
    margin-bottom: 24px;
  }
  .dialog-desc {
    margin-bottom: 24px;
  }
  .letter-btn {
    width: 100%;
    height: 46px;
    line-height: 46px;
    border-radius: 4px;
  }
}
.img-box /deep/.el-dialog--center .el-dialog__body {
  padding: 0;
  .dialog-img {
    margin-bottom: 0px;
    width: 100%;
    height: auto;
    display: block;
  }
}
.w-300 {
  width: 270px;
}
</style>
