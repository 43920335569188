import { createRouter, createWebHistory } from "vue-router";
import Home from "../views/Home.vue";
import HomePage from "../views/HomePage.vue";
import Theme from "../views/Theme.vue";
// import mineRouter from "./modules/mine";
import personRouter from "./modules/person";
import nftRouter from "./modules/nft";
import contentRouter from "./modules/content";
import quoteRouter from "./modules/quote";
import cupRouter from "./modules/worldcup";
import trransRouter from "./modules/transaction";
import fiatRouter from "./modules/fiat";
import documentaryRouter from "./modules/documentary";
import financialRouter from "./modules/financial";
import flashAgainstRouter from "./modules/flash-against";
import foreignExchangeRouter from "./modules/foreign-exchange";
import loanRouter from "./modules/loan";
import store from "@/store";

const routes = [
  {
    path: "/:lang/",
    name: "Home",
    meta: {
      scrollTop: 0,
      content: {
        keywords: "",
        description: "",
      },
    },
    component:
      process.env.VUE_APP_NEW_HOMEPAGE == 1
        ? HomePage
        : process.env.VUE_APP_NEW_HOMEPAGE == 2
        ? Theme
        : Home,
  },
  {
    path: "/:lang/about",
    name: "About",
    meta: {
      content: {
        keywords: "",
        description: "",
      },
    },
    component: () => import("../views/About.vue"),
  },
  {
    path: "/:lang/login",
    name: "Login",
    meta: {
      content: {
        keywords: "",
        description: "",
      },
    },
    component: () => import("../views/Login.vue"),
  },
  {
    path: "/:lang/register",
    name: "Register",
    meta: {
      content: {
        keywords: "",
        description: "",
      },
    },
    component: () => import("@/views/Register.vue"),
  },
  {
    path: "/:lang/forgotPassword",
    name: "ForgotPassword",
    meta: {
      content: {
        keywords: "",
        description: "",
      },
    },
    component: () => import("@/views/ForgotPassword.vue"),
  },
  {
    path: "/:lang/download",
    name: "download",
    meta: {
      content: {
        keywords: "",
        description: "",
      },
    },
    component: () => import("@/views/DownLoad.vue"),
  },
  // ...mineRouter,
  personRouter,
  ...contentRouter,
  ...quoteRouter,
  ...trransRouter,
  ...financialRouter,
  ...fiatRouter,
  ...documentaryRouter,
  ...flashAgainstRouter,
  ...nftRouter,
  ...cupRouter,
  ...foreignExchangeRouter,
  ...loanRouter,
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior(to, from, savedPosition) {
    // 解决路由跳转后 会滚动到底部,所有页面刷新会滚动到底部
    if (savedPosition) {
      if (to.name === "Home") {
        return savedPosition;
      } else {
        return { x: 0, y: 0 };
      }
    } else {
      document.body.scrollTop = document.documentElement.scrollTop = 0;
    }
  },
});
router.beforeEach((to, from, next) => {
  //   var nowlocal = "";
  // if (store) {
  //   if (store.state.user?.local == "zh") {
  //     nowlocal = "zh-cn";
  //   } else if (store.state.user?.local == "tc") {
  //     nowlocal = "zh-hk";
  //   } else {
  //     nowlocal = store.state.user?.local;
  //   }
  // }
  // this.base = `/${nowlocal}` + process.env.BASE_URL
  // 配置SEO
  /*********动态修改keywords和description*************/
  if (Object.keys(to.meta).length > 0 && to.matched.length > 0) {
    let this_meta = to.matched[to.matched.length - 1].meta;
    console.log(this_meta, "---this_meta---");
    let head = document.getElementsByTagName("head");
    let meta_keyword = document.createElement("meta");
    if (document.querySelector('meta[name="keywords"]')) {
      document
        .querySelector('meta[name="keywords"]')
        .setAttribute("content", window.VUE_KEYWORDS);
    } else {
      meta_keyword.setAttribute("name", "keywords");
      meta_keyword.setAttribute("content", window.VUE_KEYWORDS);
      head[0].appendChild(meta_keyword);
    }
    let meta_description = document.createElement("meta");
    if (document.querySelector('meta[name="description"]')) {
      document
        .querySelector('meta[name="description"]')
        .setAttribute("content", window.VUE_DESCRIPTION);
    } else {
      meta_description.setAttribute("name", "description");
      meta_description.setAttribute("content", window.VUE_DESCRIPTION);
      head[0].appendChild(meta_description);
    }
  }
  // if (to.meta.content) {
  //   let head = document.getElementsByTagName("head");
  //   let meta = document.createElement("meta");
  //   document
  //     .querySelector('meta[name="keywords"]')
  //     .setAttribute("content", window.VUE_KEYWORDS);
  //   document
  //     .querySelector('meta[name="description"]')
  //     .setAttribute("content", window.VUE_DESCRIPTION);
  //   meta.content = to.meta.content;
  //   head[0].appendChild(meta);
  // }
  let lang = store.state.user?.local;
  let newRoute = {
    // path: "/" + lang + to.path,
    // query: to.query,
    // params: to.params,
  };
  let arr = to.path.split("/");
  let localary = store.state.user?.localary;
  let index = -1;
  if (
    !store.state.user?.systemInfo ||
    (lang === "en" &&
      (!localStorage.getItem("isFirset") ||
        localStorage.getItem("isFirset") == "1"))
  ) {
    store.dispatch("user/changeSystemConfig").then(() => {
      let defaultIndex = store.state.user?.systemInfo?.findIndex((ele) => {
        return ele.key == "default_language_tag";
      });
      let default_language_tag =
        store.state.user?.systemInfo[defaultIndex].value;
      console.log("default_language_tag", default_language_tag);
      lang = default_language_tag;
      if (lang == "zh") {
        lang = "cn";
      } else if (lang == "tc") {
        lang = "hk";
      } else if (lang == "vn") {
        lang = "vi";
      }

      if (localary && localary.length > 0) {
        index = localary.findIndex((ele) => {
          let nowlang = "";
          if (arr[1] == "cn") {
            nowlang = "zh";
          } else if (arr[1] == "hk") {
            nowlang = "tc";
          } else if (arr[1] == "vi") {
            nowlang = "vn";
          } else {
            nowlang = arr[1];
          }
          return ele.language_tag == nowlang;
        });
      }

      if (index > -1 && lang != arr[1]) {
        if (arr[2]) {
          to.path = `/${arr[2]}`;
        } else {
          to.path = `/`;
        }
      }
      newRoute = {
        path: "/" + lang + to.path,
        query: to.query,
        params: to.params,
      };
      console.log("to.path", lang);
      if (to.path.indexOf("person") > -1) {
        if (!store.state.user.token) {
          if (arr[1] == lang) {
            next();
          } else {
            next({
              path: `/${lang}/login?redirect=${to.path}`,
            });
          }
        } else {
          if (arr[1] == lang) {
            next();
          } else {
            next(newRoute);
          }
          // console.log(to.path, from.path);
        }
      } else if (
        (to.name === "Deal" && !store.state.exchange.deal_data) ||
        (to.name === "Lever" && !store.state.exchange.lever_data) ||
        (to.name === "secondlever" && !store.state.exchange.second_data) ||
        (to.name === "secondlever" && !store.state.exchange.lever_data)
      ) {
        const setfun =
          to.name === "Deal"
            ? "exchange/SET_DEAL"
            : to.name === "Lever"
            ? "exchange/SET_LEVER"
            : "exchange/SET_SECOND";
        store
          .dispatch("exchange/gettradingDetail", {
            currency_match_type:
              to.name === "Deal" ? 1 : to.name === "Lever" ? 2 : 3,
          })
          .then((data) => {
            console.log(data, "data");
            store.commit(setfun, data);
            if (to.name === "secondlever" && !store.state.exchange.lever_data) {
              store.commit("exchange/SET_LEVER", data);
            }
            if (arr[1] == lang) {
              next();
            } else {
              next(newRoute);
            }
          })
          .catch(() => {});
      } else if (to.name === "Stock") {
        store
          .dispatch("exchange/stockDetail", {
            id: store.state.exchange.stock_data_id || null,
          })
          .then((data) => {
            console.log(data, "data");
            store.commit("exchange/SET_STOCK", data);
            if (arr[1] == lang) {
              next();
            } else {
              next(newRoute);
            }
          })
          .catch(() => {
            if (arr[1] == lang) {
              next();
            } else {
              next(newRoute);
            }
          });
      } else if (
        to.name === "Login" &&
        !to.query?.redirect &&
        to.redirectedFrom?.fullPath
      ) {
        if (arr[1] == lang) {
          next();
        } else {
          const toLoginRoute = {
            path: "/" + lang + "/login",
            query: { redirect: to.redirectedFrom?.fullPath },
            params: to.params,
          };
          next(toLoginRoute);
        }
      } else {
        if (arr[1] == lang) {
          next();
        } else {
          next(newRoute);
        }
      }
    });
  } else {
    if (lang == "zh") {
      lang = "cn";
    } else if (lang == "tc") {
      lang = "hk";
    } else if (lang == "vn") {
      lang = "vi";
    }

    if (localary && localary.length > 0) {
      index = localary.findIndex((ele) => {
        let nowlang = "";
        if (arr[1] == "cn") {
          nowlang = "zh";
        } else if (arr[1] == "hk") {
          nowlang = "tc";
        } else if (arr[1] == "vi") {
          nowlang = "vn";
        } else {
          nowlang = arr[1];
        }
        return ele.language_tag == nowlang;
      });
    }

    if (index > -1 && lang != arr[1]) {
      if (arr[2]) {
        to.path = `/${arr[2]}`;
      } else {
        to.path = `/`;
      }
    }
    newRoute = {
      path: "/" + lang + to.path,
      query: to.query,
      params: to.params,
    };
    // console.log("to.path", newRoute, from.path);
    if (to.path.indexOf("person") > -1) {
      if (!store.state.user.token) {
        if (arr[1] == lang) {
          next();
        } else {
          next({
            path: `/${lang}/login?redirect=${to.path}`,
          });
        }
      } else {
        if (arr[1] == lang) {
          next();
        } else {
          next(newRoute);
        }
        // console.log(to.path, from.path);
      }
    } else if (
      (to.name === "Deal" && !store.state.exchange.deal_data) ||
      (to.name === "Lever" && !store.state.exchange.lever_data) ||
      (to.name === "secondlever" && !store.state.exchange.second_data) ||
      (to.name === "secondlever" && !store.state.exchange.lever_data)
    ) {
      const setfun =
        to.name === "Deal"
          ? "exchange/SET_DEAL"
          : to.name === "Lever"
          ? "exchange/SET_LEVER"
          : "exchange/SET_SECOND";
      store
        .dispatch("exchange/gettradingDetail", {
          currency_match_type:
            to.name === "Deal" ? 1 : to.name === "Lever" ? 2 : 3,
        })
        .then((data) => {
          console.log(data, "data");
          store.commit(setfun, data);
          if (to.name === "secondlever" && !store.state.exchange.lever_data) {
            store.commit("exchange/SET_LEVER", data);
          }
          if (arr[1] == lang) {
            next();
          } else {
            next(newRoute);
          }
        })
        .catch(() => {});
    } else if (to.name === "Stock") {
      store
        .dispatch("exchange/stockDetail", {
          id: store.state.exchange.stock_data_id || null,
        })
        .then((data) => {
          console.log(data, "data");
          store.commit("exchange/SET_STOCK", data);
          if (arr[1] == lang) {
            next();
          } else {
            next(newRoute);
          }
        })
        .catch(() => {
          if (arr[1] == lang) {
            next();
          } else {
            next(newRoute);
          }
        });
    } else if (
      to.name === "Login" &&
      !to.query?.redirect &&
      to.redirectedFrom?.fullPath
    ) {
      if (arr[1] == lang) {
        next();
      } else {
        const toLoginRoute = {
          path: "/" + lang + "/login",
          query: { redirect: to.redirectedFrom?.fullPath },
          params: to.params,
        };
        next(toLoginRoute);
      }
    } else {
      // debugger;
      if (arr[1] == lang) {
        next();
        // return;
      } else {
        next(newRoute);
      }
    }
  }
});
export default router;
